import React, { createElement } from 'react';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import Autocomplete from './search';
import { ResultItem } from './result-item';

const appId = 'RFSPADR18I';
const apiKey = '9dbc9e7ac9a8f05bd88390136e9fcb1f';
const searchClient = algoliasearch(appId, apiKey);

function SearchContainer() {
  return (
      <Autocomplete
      />
  );
}

export default SearchContainer;
