import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import algoliasearch from 'algoliasearch/lite';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import {colors, media} from 'theme';
// ...
const searchClient = algoliasearch(
  'RFSPADR18I',
  '9dbc9e7ac9a8f05bd88390136e9fcb1f'
);

export default function Autocomplete() {
  // (1) Create a React state.
  const inputRef = React.useRef(null);
  const [autocompleteState, setAutocompleteState] = React.useState({});
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: 'title',
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'zamaleaks',
                      query,
                      params: {
                        hitsPerPage: 10,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    []
  );

  return (
    <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
      <form
        css={{
          display: 'flex',
          flex: '0 0 auto',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: '0.25rem',
          paddingRight: '0.25rem',
          [media.lessThan('expandedSearch')]: {
            justifyContent: 'flex-end',
          },
          [media.lessThan('large')]: {
            marginLeft: 10,
          },
          // TODO: Something like this could show the full search box in more cases
          // but it currently breaks its expanding animation.
          // [media.between('mediumSearch', 'largerSearch')]: {
          //   width: 'calc(100% / 8)',
          // },
          [media.greaterThan('expandedSearch')]: {
            minWidth: 100,
            width: 'calc(100% / 5)',
          },
        }}
        className="aa-Form"
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        >
          <div className="aa-InputWrapperPrefix"></div>
          <div className="aa-InputWrapper">
            <input
              css={{
                width: '100%',
                appearance: 'none',
                background: 'transparent',
                border: 0,
                color: colors.white,
                fontSize: 18,
                fontWeight: 300,
                fontFamily: 'inherit',
                position: 'relative',
                padding: '4px 29px 4px 4px',
                backgroundImage: 'url(/search.svg)',
                backgroundSize: '16px 16px',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'center',
                backgroundPositionX: '98%',

                ':focus': {
                  outline: 0,
                  backgroundColor: colors.lighter,
                  borderRadius: '0.25rem',
                },

                [media.lessThan('expandedSearch')]: {
                  fontSize: 16,
                  width: '16px',
                  transition: 'width 0.2s ease, padding 0.2s ease',
                  paddingRight: '16px',

                  ':focus': {
                    paddingRight: '29px',
                    width: '8rem',
                    outline: 'none',
                  },
                },
              }}
              id="algolia-doc-search"
              type="search"
              placeholder="بحث"
              aria-label="Search docs"
              dir="rtl"
              className="aa-Input"
              ref={inputRef}
              {...autocomplete.getInputProps({})}
            />
        </div>
      </form>
      {/* <form
        className="aa-Form"
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        <div className="aa-InputWrapperPrefix">
        </div>
        <div className="aa-InputWrapper">
          <input
            className="aa-Input"
            ref={inputRef}
            {...autocomplete.getInputProps({})}
          />
        </div>
      </form> */}
      <div className="aa-Panel" {...autocomplete.getPanelProps({})}>
        {autocompleteState.isOpen &&
          autocompleteState.collections.map((collection, index) => {
            const { source, items } = collection;
            console.log('items:::',items);
            return (
              <div key={`source-${index}`} className="aa-Source">
                {items.length > 0 && (
                  <ul className="aa-List" {...autocomplete.getListProps()}>
                    {items.map((item) => (
                      <li
                        key={item.objectID}
                        className="aa-Item"
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                      >
                        <a href={item.url}>
                        {item.title}
                        <div className="result-content" dangerouslySetInnerHTML={{__html: item._highlightResult.contents.value.substring(0, 200)}}>
                        </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
